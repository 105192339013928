import React from 'react';
import AuthorBlock from './AuthorBlock';
import AuthorStories from './AuthorStories';
import StatsBlock from './StatsBlock';
import css from '../static/styles.css';
import Axios from 'axios';

export default class Home extends React.Component {
  componentDidMount() {
    window.app.username='wally_lawless';
    const username = window.app.username;
    Axios.get(`/api/${username}/todayStats`)
    .then((response) => {
      this.setState({
        authorStats: response.data
      })
    })

    // Axios.get('/api/weekStats')
    // .then((response) => {
    //   this.setState({
    //     weekStats: response.data
    //   })
    // })

    Axios.get(`/api/${username}/hottestStory`)
    .then((response) => {
      this.setState({
        hotStory: response.data
      })
    })

    Axios.get(`/api/${username}/hottestPart`)
    .then((response) => {
      this.setState({
        hotPart: response.data
      })
    });
  }

  render() {
    if(!this.state) return null;

    return (
      <div>
        <h1 className={css.primary}>Home</h1>
        <AuthorBlock />
        <div className='summary-stats'>
          {this.state.authorStats ?
          <StatsBlock title="Today's Stats" metrics={ { reads: this.state.authorStats.today_reads, votes: this.state.authorStats.today_votes, comments: this.state.authorStats.today_comments,}} />
          : null}
          {this.state.hotStory ?
          <StatsBlock title={`Hottest Story: ${this.state.hotStory.title}`} /*link={`/story/${this.state.hotStory.id}`}*/ metrics={ { reads: this.state.hotStory.today_reads, votes: this.state.hotStory.today_votes, comments: this.state.hotStory.today_comments,}} />
          : null}
          {this.state.hotPart ?
          <StatsBlock title={`Hottest Part: ${this.state.hotPart.title}`} metrics={ { reads: this.state.hotPart.today_reads, votes: this.state.hotPart.today_votes, comments: this.state.hotPart.today_comments,}} />
          : null}
          {this.state.authorStats ?
          <StatsBlock title="This week's Stats" metrics={ { reads: this.state.authorStats.week_reads, votes: this.state.authorStats.week_votes, comments: this.state.authorStats.week_comments,}} />
          : null}
          <div className='statsBlock'>
            <h3>Time since last publish</h3>
            <span className='count'>8 days</span>
          </div>
          <div className='statsBlock'>
            <h3>Average publish time</h3>
            <span className='count'>21 days</span>
          </div>
        </div>
        <h3>Your stories</h3>
        <AuthorStories />
      </div>
    )
  }
}