import React from 'react';
// import axios from 'axios';
import CoverCard from './CoverCard';
import {Link} from 'react-router-dom';
import SimpleNum from './SimpleNum';

export default class StoryCard extends React.Component {
  render() {
    const story = this.props.story;
    return (
      <div className='story-card'>
        {/* <Link className='title' to={`/story/${story.id}`}>{story.title}</Link> */}
        <span className='title' to={`/story/${story.id}`}>{story.title}</span>
        <div className='card-body'>
          <CoverCard story={story}/>
          <div className='stats'>
            <div>Reads: {SimpleNum({number:story.reads})} total / {SimpleNum({number:story.todayReads})} today</div>
            <div>Votes: {SimpleNum({number:story.votes})} total / {SimpleNum({number:story.todayVotes})} today</div>
            <div>Comments: {SimpleNum({number:story.comments})} total / {SimpleNum({number:story.todayComments})} today</div>
          </div>
        </div>
      </div>
    )
  }
}