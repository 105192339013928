import React from 'react';
import Axios from 'axios';
import StoryCard from './StoryCard'

export default class AuthorStories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    Axios.get('/api/author/wally_lawless/stories')
    .then((response) => {
      this.setState({stories: response.data});
    })
  }

  render() {
    if(!this.state.stories) return null;
    return (
      <div className='author-story-grid'>
        {this.state.stories.map(story => {
          return <StoryCard key={story.id} story={story} />
        })}
      </div>
    )
  }
}