import React from 'react';

export default class NotFound extends React.Component {
  render() {
    return (
      <div className='not-found'>
        <h1>Uh-oh! Looks like something went wrong...</h1>
        <p>Don't worry, I'm sure everything is fine. This is likely just a temporary glitch.</p>
        <p>Please try again in a little bit.</p>
        <p>If all else fails, please email <a href="mailto:mystoryinfoapp@gmailcom">mystoryinfo@gmail.com</a> for help.</p>
      </div>
    )
  }
}