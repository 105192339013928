import React from 'react';

export default class Confirm extends React.Component {
  render() {
    return (
      <div className='confirmation-page'>
        <h1>All Set!</h1>

        <p>Everything is set up for MyStoryInfo to start capturing data about your stories. You will get an email once we've gathered enough information for the app to be useful for you.</p>

        <p>Oh, and I'm hoping everything will be a bit prettier the next time you're here too 😃</p>

        <p>In the meantime, please just keep writing and publishing your stories as you normally would. MyStoryInfo will be able to detect these changes and once finished will help you get the most out of your hard work.</p>

        <p>Happy writing!<br/>
        - MyStoryInfo
        </p>
      </div>
    )
  }
}