/**
 * Shortens a number by using M/K notation to indicate millions / thousands
 * @param {number} props The number to be reformatted
 */

function SimpleNum(props) {
  let number = 0;
  if(props.children) {
    number = parseInt(props.children);
  } else {
    number = parseInt(props.number);
  }

  if(isNaN(number)) {
    return 'N/A';
  }

  if(number >= 99999999) {
    return `${Math.floor(number / 1000000)}M`
  }

  if(number >= 1000000) {
    return `${Math.floor(number / 1000000)/10}M`
  }

  if(number >= 99999) {
    return `${Math.floor(number / 1000)}K`;
  }

  if(number > 1000) {
    return `${Math.floor(number / 100)/10}K`
  }
  return number;
}

export default SimpleNum