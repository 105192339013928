import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import Analytics from 'react-router-ga';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

const bugsnagClient = bugsnag('e164a417a892955fff24ce4571a37d72')
bugsnagClient.use(bugsnagReact, React)

const ErrorBoundary = bugsnagClient.getPlugin('react')
ReactDOM.render((
  <ErrorBoundary>
    <BrowserRouter>
      <Analytics id="UA-128339564-1">
        <App />
      </Analytics>
    </BrowserRouter>
  </ErrorBoundary>
), document.getElementById('root'));