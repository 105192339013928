import React from 'react';
import Axios from 'axios';
import {BrowserRouter} from 'react-router-dom';

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.registerUser = this.registerUser.bind(this);
  }

  registerUser(event) {
    const token = window.localStorage.getItem('token');
    Axios.post('/api/user/updateProfile', {
      email: document.getElementById('emailField').value,
    },
    { headers: { 'Authentication': `Bearer ${token}` }})
    .then((response) => {
      this.props.history.push('/confirm');
    })
  }

  render() {

    return (
      <div className='auth-form'>
        <h1>Almost done!</h1>

        <p>
          MyStoryInfo is currently in ongoing development, but it's getting oh-so-close to being ready! I'm really excited to launch it soon.
        </p>

        <p>
          In the meantime, your story stats will start being collected so that once the app launches there will be read data for you to look at. In order to let you know once your data and the app is ready, we need your email address in order to let you know.
        </p>

        <h3>Ready? Let's go!</h3>
        <label forname="emailField">Your email address:</label><input id='emailField' type='text' name='email' size='40'/><br/>
        <button onClick={this.registerUser}>Go</button>
      </div>
    )
  }
}
