import React from 'react';
import {Link} from 'react-router-dom';

export default class ConnectionError extends React.Component {
  render() {
    return (
      <div>
        <h1>Something went wrong connecting to your Wattpad account.</h1>
        <Link to='/'>Please try again</Link>
      </div>
    )
  }
}