import React from 'react';
import { validateToken } from '../lib/authentication';
import ConnectWPButton from './ConnectWPButton';
import Sample from './Sample';

export default class Landing extends React.Component {

  componentWillMount() {
    const token = window.localStorage.getItem('token');
    if(token) {
      validateToken(token)
      .then((response)=> {
        if(response.data && response.data.username) {
          window.app = window.app || {};
          window.app.username = response.data.username;
          this.props.history.push('/confirm');
        }
      })
    }
  }

  render() {
    return (
      <div>
        <h1>Tired of manually tracking your Wattpad story stats in a spreadsheet?</h1>
        <h2>Me too, it's 2019, there's a better way! 😃</h2>

        <p>You might not even know that Wattpad has analytics for writers, but that's ok, you don't need it. I'm building a tool that gathers tonnes of data about your stories that you can actually use to make decisions. You'll be able to slice and dice the numbers to drill-in to exactly what you want to know.</p>

        <p>Over time, as data continues to come in you'll get new insights that you never thought possible, or only heard of casually through some other author's Wattpad story about how to get on the hot list. But unlike those stories, they'll be customized to your story and your audience so you can maximize your efforts.</p>

        <h2>Intrigued? Of course you are!</h2>

        <p>Connect your Wattpad account so that data for your stories starts being gathered. I'm still actively developing the tool and haven't launched it yet. However this will guarantee that once it's launched, you'll have as much data as possible at your disposal.</p>

        <ConnectWPButton {...this.props}/>

        <div style={{marginTop: '24px', borderTop:'1px solid #ccc'}}>
          <Sample {...this.props}/>
        </div>
      </div>
    )
  }
}