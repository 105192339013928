import React from 'react';
import Axios from 'axios';

export default class StoryStats extends React.Component {
  componentDidMount() {
    Axios.get(`/api/story/${this.props.match.params.storyId}`)
    .then((response) => {
      this.setState({stats: response.data})
    })
  }

  render() {
    if(!this.state) return null;

    const stats = this.state.stats;

    return (
      <h1>{stats.title} Stats</h1>
    )
  }
}