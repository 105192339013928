import React from 'react'
import Axios from 'axios'
import SimpleNum from './SimpleNum'

export default class AuthorBlock extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    Axios.get('/api/author/wally_lawless/stats')
    .then((response) => {
      this.setState(response.data);
    })
  }

  render() {
    if(!this.state.username) return null;

    return (
      <div className='author-block'>
        <img className='avatar' src={`https://a.wattpad.com/useravatar/${this.state.username}.256.jpg`} />
        <div className='author-info'>
          <h3>{this.state.username}</h3>
          <div className='stats'>
            <div>Stories: <span><SimpleNum>{this.state.stories}</SimpleNum></span></div>
            {/* <div>Parts: <span><SimpleNum>{this.state.parts}</SimpleNum></span></div> */}
            <div>
              <span className='count'><SimpleNum>{this.state.reads}</SimpleNum></span> reads /
              <span className='count'><SimpleNum>{this.state.votes}</SimpleNum></span> votes /
              <span className='count'><SimpleNum>{this.state.comments}</SimpleNum></span> comments
            </div>
            <div><span className="count"><SimpleNum>{this.state.followers}</SimpleNum></span> followers</div>
          </div>
        </div>
      </div>
    )
  }
}