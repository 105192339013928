import React from 'react';

export default class ConnectWPButton extends React.Component {
  constructor(props) {
    super(props);
    this.connectWP = this.connectWP.bind(this);
  }
  connectWP() {
    // window.location.href = "https://www.wattpad.com/oauth/code?redirect_uri=http://127.0.0.1:3000/auth/example/callback&scope=read&api_key=su2NpmmZFRPGq1HKJGFYqmzbSF9lqMhwrWfPIUfPt1uK";
    this.props.history.push('/wpConnect');
  }

  render() {
    return (
      <button onClick={this.connectWP}>Connect your Wattpad account to continue</button>
    )
  }
}