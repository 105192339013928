import React from 'react';
import StatsBlock from './StatsBlock';
import StoryCard from './StoryCard';
import ConnectWPButton from './ConnectWPButton';

export default class Sample extends React.Component {
  render() {
    return(
      <div>
        <h1>Here's a preview of what you'll get:</h1>
        <div className='summary-stats'>
          <StatsBlock title={`Today's Stats:`} metrics={{reads:100,votes:12,comments:6}}/>
          <StatsBlock title={`Hottest Story: Your story`} metrics={{reads:100,votes:12,comments:6}}/>
          <StatsBlock title={`Hottest Part: Chapter 42`} metrics={{reads:100,votes:12,comments:6}}/>
          <StatsBlock title={`This week's Stats:`} metrics={{reads:321,votes:123,comments:67}}/>
          <div className='statsBlock'>
            <h3>Time since last publish:</h3>
            <span className='count'>8 days</span>
          </div>
          <div className='statsBlock'>
            <h3>Average publish frequency:</h3>
            <span className='count'>21 days</span>
          </div>
        </div>
        <h3>Sample story</h3>
        <div className='author-story-grid'>
          <StoryCard story={{title:'Your story', coverUrl: '/dist/sample_cover.jpg', reads:72627, votes: 212, comments:2100, todayReads:100, todayVotes:12,todayComments:6}} />
        </div>

        <h3>Ready to get all this (and more) for your own stories?</h3>
        <ConnectWPButton {...this.props}/>
      </div>
    )
  }
}