import React from 'react';
import { Link } from 'react-router-dom';

export default class StatsBlock extends React.Component {
  render() {
    return (
      <div className="statsBlock">
        <h3>{this.props.link ? <Link to={this.props.link}>{this.props.title}</Link> : this.props.title}</h3>
        <div className='stats'>
          <div><span className='count'>{this.props.metrics.reads}</span>reads</div>
          <div><span className='count'>{this.props.metrics.votes}</span>votes</div>
          <div><span className='count'>{this.props.metrics.comments}</span>comments</div>
        </div>
      </div>
    )
  }
}