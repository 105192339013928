import React from 'react';
import ReactDOM from 'react-dom';
import Index from './components/Index'
import Home from './components/Home'
import Landing from './components/Landing'
import StoryStats from './components/StoryStats'
import ConnectionError from './components/ConnectionError'
import Confirm from './components/Confirm';
import Sample from './components/Sample';
import WPAuth from './components/WPAuth';
import NotFound from './components/NotFound';
import {Switch, Route} from 'react-router-dom';

export default class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div id='app'>
          <Switch>
            <Route exact path='/' component={Landing} />
            <Route path='/connect' component={Index} />
            <Route path='/home' component={Home} />
            <Route path='/confirm' component={Confirm} />
            <Route path='/sample' component={Sample} />
            <Route path='/story/:storyId' component={StoryStats} />
            <Route path='/connectionError' component={ConnectionError} />
            <Route path='/wpConnect' component={WPAuth} />
            <Route component={NotFound} />
          </Switch>
        </div>
        <footer>
          <div className='content'>
            <h4>What's really going on here?</h4>
            <p>
              I used to have a reminder on my phone to update my Wattpad story stats every morning. Sometimes I'd forget, sometimes I wouldn't have a computer nearby, or I'd get the notification when I'm offline and can't do anything about it.  This meant that I'd have gaps in my data and that would drive me CRAZY!
            </p>
            <p>
              Eventually, the frustration welled to the point where I couldn't take it anymore so I spent some time automating the whole thing. It was awesome!
            </p>
            <p>
              As tools like this tend to do, it grew over time. Then I showed it to another Wattpad writer and immediately they asked "can I use this too?". I gave it some thought, and the result is what you see here.
            </p>
            <p>
              It's still early days, so while the basic data capture and some simple math are already in place, there's SO much more that I plan to introduce. It's going to take some time, and some data, in order to make sure that the most important information is at writer's fingertips.
            </p>
            <p>
              I hope you stick around for the journey, I promise you'll learn something to improve your writing craft.
            </p>
          </div>
          &copy; 2019 MyStoryInfo
        </footer>
      </React.Fragment>
    )
  }
}
