import React from 'react'
import Axios from 'axios'

export default class WPAuth extends React.Component {

  constructor(props) {
    super(props);
    this.updateForm = this.updateForm.bind(this);
    this.sendWPAuth = this.sendWPAuth.bind(this);

    this.state = {
      username: '',
      password: ''
    }
  }

  sendWPAuth(event) {
    event.preventDefault();

    if(this.state.username.length>0 && this.state.password.length>0) {
      Axios.post('https://api.wattpad.com/v4/sessions', {
        username: this.state.username,
        password: this.state.password,
        type: 'wattpad',
        fields:'token,user'
      },
      {
        headers: {
          'Accept': 'applicaton/json'
        }
      })
      .then((response) => {
        Axios.post('/api/user', {
          username: response.data.user.username,
          token: response.data.token
        })
        .then((response) => {
          if(response.data.username) {
            window.localStorage.setItem('token', response.data.token);
            if(!response.data.hasEmail) {
              this.props.history.push('/connect');
            } else {
              this.props.history.push('/confirm');
            }
          }
        })
      })
      .catch((err) => {
        console.log(err);
      });
    }
  }

  updateForm() {
    this.setState({
      username: document.getElementById('username').value,
      password: document.getElementById('password').value
    })
  }

  render() {
    return (
      <div>
        <h1>Connect your Wattpad account</h1>

        <p>
          To get started, log into your Wattpad account. You'll then select which of your stories you'd like to start tracking.
        </p>

        <form className="auth-form" action="">
          <label forname="username">Your wattpad username:</label><input type="text" id="username" name="username" placeholder="" onChange={this.updateForm} value={this.state.username} /><br />
          <label forname="password">Your wattpad password:</label><input type="password" id="password" name="password" placeholder="" onChange={this.updateForm} value={this.state.password} /><em>* MyStoryInfo never sees or stores your Wattpad password</em><br />
          <button onClick={this.sendWPAuth}>Log in</button><br />
        </form>
      </div>
    )
  }
}